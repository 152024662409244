import { useEffect } from 'react';
import { useRouter } from 'next/router';
import Spinner from 'src/@core/components/spinner';
import { useSession } from 'next-auth/react';

export const getHomeRoute = (locale = '') => {
  let loc = '';

  if (locale) {
    loc = `/${locale}`;
  }

  return `${loc}/dashboard`;
};

const Index = () => {
  const router = useRouter();
  const session = useSession();

  useEffect(() => {
    if (!router.isReady) {
      return;
    }

    if (session.data?.user?.permissions) {
      const homeRoute = getHomeRoute(router.locale);

      // Redirect user to Home URL
      router.replace(homeRoute);
    }
  }, [router, session.data?.user?.permissions]);

  return <Spinner />;
};

export default Index;
